.member-link  {
  background-color: #000 !important;
  background: #000; 
  margin-left: 11px !important;
  box-shadow: inset 0 0 0 1px #fff !important;

  &:hover {
    background-color: #B02E34 !important;
    background: #B02E34 !important;
    box-shadow: inset 0 0 0 1px #B02E34 !important;
    color: #fff !important;
  }

  @media (max-width: 991px) {
    margin-left: 0 !important;
    box-shadow: none !important;
  }
}

.PortalPage {
  background: #000;

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .c-header {
    border-bottom: 1px solid #252525;
  }

  .c-hero {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    overflow: hidden;
    padding-top: 38px;
    padding-bottom: 18px;

    &__t-cell {
      text-align: left;
    }

    &__legend {
      font-weight: 700;
      font-size: 16px;
      line-height: 18.75px;
      color:#B02E34;
      text-transform: uppercase;

      @media (max-width: 560px) {
        font-size: 14px;
        line-height: 16.41px;
        margin-bottom: 4px;
      }
    }

    &__title {
      margin: 0;
      padding: 0;
      margin-bottom: 9px;
      font-size: 56px;
      line-height: 65.63px;
      font-weight: 700;

      @media (max-width: 560px) {
        font-size: 36px;
        line-height: 42.19px;
        font-weight: 700;
      }
    }

    &__content {
      font-weight: 400;
      font-size: 14px;
      line-height: 26.4px;
      color: #fff;
      max-width: 450px;
      margin: 0;
      padding: 0;

      @media (max-width: 560px) {
        font-size: 14px;
        line-height: 26.4px;
        max-width: 85%;
      }
    }
  }

  .portal-form {
    background: #fff;
    border-bottom: 1px solid #E3E3E3;
  }

  .portal-form__wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 26px 12px;
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    @media (max-width: 560px) {
      flex-direction: column;
      gap: 20px;
      padding: 26px 0 40px;
    }

    &--left {
      font-weight: 700;
      font-size: 16px;
      line-height: 18.75px;
      text-transform: uppercase;
      color: #000;
    }

    &--right {
      div {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;

        @media (max-width: 992px) {
          align-items: center;
          justify-content: center;
        }

        a {
          border-radius: 42px;
          border: 1px solid #000;
          padding: 9px 17px;
          font-weight: 700;
          font-size: 13px;
          color: #000;
          text-transform: uppercase;
          background: #fff;
          margin: 0;
          text-decoration: none;
          transition: all .2s ease-in-out;

          @media (max-width: 992px) {
            font-size: 12px;
            line-height: 14.03px;
          }

          &:hover {
            background: #000;
            color: #fff;
          }
        }
      }

      .portal-form__list--active {
        background: #000;
        color: #fff;
      }
    }
  }

  .video {
    background: #fff !important;
    padding: 50px 0;

    &__title {
      font-weight: 700;
      font-size: 30px;
      line-height: 35.16px;
      color: #000;
      margin-bottom: 20px;

      @media (max-width: 992px) {
        font-size: 25px;
        line-height: 29.3px;
      }
    }

    &__item {
      border: 1px solid #D8D8D8;
      border-radius: 5px;

      &--img {
        height: 285px;
        overflow: hidden;

        @media (max-width: 991px) {
          height: 224px;
        }

        @media (max-width: 768px) {
          height: 163px;
        }

        @media (max-width: 560px) {
          height: initial;
          max-height: 336px;
        }

        @media (max-width: 420px) {
          height: initial;
          max-height: 233px;
        }

        a {
          overflow: hidden;
          width: 100%;
          height: 100%;
          display: block;
          img {
            transition: 0.4s;
            object-fit: cover;
            height: 100%;

            &:hover {
              transform: scale(1.06);
            }
          }
        }
      }

      &--content {
        padding: 30px;

        legend {
          font-weight: 700;
          font-size: 16px;
          line-height: 18.75px;
          color: #B02E34;
          text-transform: uppercase;

          @media (max-width: 992px) {
            font-size: 14px;
            line-height: 16.41px;
          }
        }

        h3 {
          font-weight: 700;
          font-size: 22px;
          line-height: 25.78px;
          color: #000;
          text-transform: uppercase;
          margin-bottom: 0;

          @media (max-width: 992px) {
            font-size: 17px;
            line-height: 19.92px;
          }

          @media (max-width: 560px) {
            margin-bottom: 0px;
          }
        }


        @media (max-width: 560px) {
          padding: 20px 30px;
          text-align: left;
        }
      }
    }
  }

  .firstgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 20px;
    }
  }
  
  .firstgrid__item {
    grid-column: span 1;
  }
  
  .firstgrid__item:nth-child(n+3) {
    grid-column: span 1;
  }

  .firstgrid__item--img {
    max-height: 435px;
   
    a {
      overflow: hidden;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      display: block;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      img {
        transition: 0.4s;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
  
        &:hover {
          transform: scale(1.06);
        }
      }
    }
  }

  .nextgrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .nextgrid__item {
    grid-column: span 1;

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 18.75px;
    }

    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    @media screen and (min-width: 561px) and (max-width: 768px) {
      .progress-block {
        padding: 1px 4px;
        width: 113px;
        gap: 2px;
        margin-left: 10px;
        margin-bottom: 10px;
      }

      .progress-completed{
        max-width: 113px;
      }
    }
  }
  
  .nextgrid__item:nth-child(n+3) {
    grid-column: span 1;
  }


  .othervideos {
    background: #fff !important;
    padding: 10px 0 50px;

    &__title {
      font-weight: 700;
      font-size: 22px;
      line-height: 25.78px;
      color: #000;
      margin-bottom: 20px;

      @media (max-width: 992px) {
        font-size: 17px;
        line-height: 19.92px;
      }

      @media (max-width: 560px) {
        text-align: center;
      }
    }

    &__item-reverse {
      border-radius: 5px;
      background:#000;
      padding: 30px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;

      &:hover {
        background:#B02E34;
      }

      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 25.78px;
        color: #fff;
        text-transform: uppercase;
        margin: 0;
        border-bottom: 0 !important;
        text-decoration: none;

        @media (max-width: 992px) {
          font-size: 17px;
          line-height: 19.92px;
        }
      }
    }

    &__item {
      border-radius: 5px;
      background:#B02E34;
      padding: 30px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;

      &:hover {
        background:#000;
      }

      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 25.78px;
        color: #fff;
        text-transform: uppercase;
        margin: 0;
        border-bottom: 0 !important;
        text-decoration: none;

        @media (max-width: 992px) {
          font-size: 17px;
          line-height: 19.92px;
        }
      }
    }
  }

  .othervideos-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  }
  
  .othervideos-grid__item {
    grid-column: span 1;

    @media (max-width: 991px) {
      .row {
        position: relative;
      }
      .order-lg-1 {
        position: absolute;
        left: 0;
        top: -2px;
      }

      h3 {
        font-size: 17px;
        font-weight: 700;
        line-height: 19.92px;
      }

      svg {
        height: 19px;
      }
    }
  }
  
  .othervideos-grid__item:nth-child(n+3) {
    grid-column: span 1;
  }

  .mintdesign__blocks__wysiwygwithimage__wysiwygwithimage {
    img {
      height: 466px;
      width: 100%;
      object-fit: cover;
      border-radius: 0;

      @media (max-width: 560px) {
        height: 259px;
      }
    }

    .b-wysiwyg {
      padding: 0;

      .container {
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
    }
    .container {
      max-width: 100%;
    }

    .order-lg-1 {
      margin: 0;
      padding: 0;

      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 26.4px;
      }

      @media (max-width: 560px) {
        font-size: 14px;
        line-height: 26.4px;
        margin-bottom: 0 !important;
      }
    }

    .order-lg-0 {
      padding-left: calc(50% - 642px);
      padding-right: 60px;
      max-width: 600px;

      @media screen and (min-width: 1691px) {
        max-width: 100%;
      }

      @media screen and (min-width: 1670px) and (max-width: 1690px) {
        max-width: 708px;
      }

      @media screen and (min-width: 1616px) and (max-width: 1669px) {
        max-width: 688px;
      }

      @media screen and (min-width: 1057px) and (max-width: 1615px) {
        max-width: 680px;
      }
     
      @media screen and (min-width: 991px) and (max-width: 1056px) {
        max-width: 680px;
      }

      @media (max-width: 1400px) {
        padding-left: calc(50% - 551px);
      }

      @media (max-width: 1199px) {
        padding-left: calc(50% - 464px);
      }

      @media (max-width: 991px) {
        max-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 90px;
        padding-top: 60px;
      }
    }

    .button-wrapper {
      @media (max-width: 1200px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
      }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        .c-button {
          padding: 16px 30px;
        }
      }
    }

    .row.align-items-center.justify-content-between {
      background:#F4F4F4;

      h2 {
        font-weight: 700;
        font-size: 30px;
        color: #000;
        line-height: 35.16px;

        @media (max-width: 560px) {
          font-size: 25px;
          line-height: 29.3px;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #000;
        line-height: 26.4px;
        padding-bottom: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        gap: 13px;

        @media (max-width: 560px) {
          font-size: 14px;
          line-height: 26.4px;
          flex-direction: column;
        }
      }
    }

    .c-button__red {
      background-color: #B02E34;
      background: #B02E34;

      &:hover {
        background-color: #000 !important;
        background: #000 !important;
      }
    }

    .c-button__black {
      background-color: #000;
      background: #000;

      &:hover {
        background-color: #B02E34;
        background: #B02E34;
      }
    }
  }

  .welcome-video {
    background: #000;

    .c-header {
      border-bottom: 1px solid #252525;
    }

    .c-hero {
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      max-width: 100%;
      overflow: hidden;
      padding-top: 38px;
      padding-bottom: 18px;

      &__t-cell {
        text-align: left;
      }

      &__legend {
        font-weight: 700;
        font-size: 16px;
        line-height: 18.75px;
        color:#B02E34;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: 560px) {
          font-size: 14px;
          line-height: 16.41px;
        }
      }

      &__title {
        margin: 0;
        padding: 0;
        margin-bottom: 9px;
        max-width: none;
        width: 100%;
        text-align: center;
        font-size: 56px;
        font-weight: 700;
        line-height: 65.63px;

        @media (max-width: 560px) {
          font-size: 36px;
          line-height: 42.19px;
        }
      }

      &__link {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 20px;
        text-decoration: none;
        bottom: 91px;
        align-items: center;

        span {
          color: #fff;
          text-transform: uppercase;
          font-size: 19px;
          font-weight: 700;
          line-height: 22.27px;
        }
        
      }
    }

    .c-hero__t-cell {
      height: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-bottom: 0;
    }


    .video-player {
      background: black;
      padding: 0 0 80px;
      border-bottom: 1px solid #252525;

      @media (max-width: 560px) {
        border-bottom: none;
      }
    
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    #videoHomePlayerHome {
      width: 80%;
      height: 80%;
      border-radius: 6px;

      @media (max-width: 560px) {
        width: 100%;
        height: 100%;
      }
    }

    #videoPlayerHome,
    #videoPlayer {
      width: 80%;
      height: 80%;
      border-radius: 6px;
      cursor: pointer;

      @media (max-width: 560px) {
        width: 100%;
        height: 100%;
      }
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #B02E34;
      border: 2px solid white;
      border-radius: 50%;
      width: 68px;
      height: 68px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s ease-in-out;
    }
    
    .play-button svg {
      width: 40px;
      height: 40px;
    }
    
    .play-button:hover {
      opacity: 0.8;
    }
    
    .play-button.hidden {
      display: none;
    }
  }
}