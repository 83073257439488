.VideoPage {
  background: #000;

  .portal-form {
    background: #fff;
    border-bottom: 1px solid #E3E3E3;
  }

  .portal-form__wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 26px 12px;
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    @media (max-width: 560px) {
      flex-direction: column;
      gap: 20px;
      padding: 26px 0 40px;
    }

    &--left {
      font-weight: 700;
      font-size: 16px;
      line-height: 18.75px;
      text-transform: uppercase;
      color: #000;
    }

    &--right {
      div {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;

        @media (max-width: 992px) {
          align-items: center;
          justify-content: center;
        }

        a {
          border-radius: 42px;
          border: 1px solid #000;
          padding: 9px 17px;
          font-weight: 700;
          font-size: 13px;
          color: #000;
          text-transform: uppercase;
          background: #fff;
          margin: 0;
          text-decoration: none;
          transition: all .2s ease-in-out;

          @media (max-width: 992px) {
            font-size: 12px;
            line-height: 14.03px;
          }

          &:hover {
            background: #000;
            color: #fff;
          }
        }
      }

      .portal-form__list--active {
        background: #000;
        color: #fff;
      }
    }
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .c-header {
    border-bottom: 1px solid #252525;
  }

  .c-hero {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    overflow: hidden;
    padding-top: 38px;
    padding-bottom: 18px;

    &__t-cell {
      text-align: left;
    }

    &__legend {
      font-weight: 700;
      font-size: 16px;
      line-height: 18.75px;
      color:#B02E34;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: 560px) {
        font-size: 14px;
        line-height: 16.41px;
      }
    }

    &__title {
      margin: 0;
      padding: 0;
      margin-bottom: 9px;
      max-width: none;
      width: 100%;
      text-align: center;
      max-width: 80%;
      margin: 0 auto;

      @media (max-width: 560px) {
        font-size: 25px;
        line-height: 29.3px;
      }
    }

    &__link {
      position: absolute;
      display: flex;
      flex-direction: row;
      gap: 20px;
      text-decoration: none;
      bottom: 91px;
      align-items: center;

      span {
        color: #fff;
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 700;
        line-height: 22.27px;
      }
      
      @media (max-width: 992px) {
        display: none;
      }
    }

    &__link-mobile {
      display: none !important;
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 20px;
      text-decoration: none;
      bottom: 91px;
      align-items: center;

      span {
        color: #fff;
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 700;
        line-height: 22.27px;
      }
      
      @media (max-width: 992px) {
        display: flex !important;
        font-size: 25px;
        line-height: 29.3px;
        bottom: initial;
        text-align: center;
        margin-bottom: 40px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .video-player {
    background: #fff;
    padding: 80px 0;
  
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .sessions {
    background: #F4F4F4;
    padding: 50px 0;
  
    &__title {
      font-weight: 700;
      font-size: 30px;
      line-height: 24.16px;
      color: #000;
      margin-bottom: 20px;
  
      @media (max-width: 992px) {
        font-size: 25px;
        line-height: 29.3px;
      }
    }

    .desktop-button {
      position: absolute;
      border: 0;
      bottom: 31px;
      left: 0;
      right: 0;
      max-width: 166px;
      margin: 0 auto;
      opacity: 0; /* Start with an opacity of 0 */
      transform: translateY(20px); /* Start 20px below its final position */
      transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition for opacity and transform */
      pointer-events: none; /* Prevent interaction when not visible */
    }
  
    .sessions-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    
      &__item {
        grid-column: span 1;
        position: relative;

        &:hover {
          .desktop-button {
            opacity: 1; /* Fade in the button */
            transform: translateY(0); /* Move the button to its final position */
            pointer-events: auto; /* Allow interaction when visible */
          }
        }
      }
      
      &__item:nth-child(n+3) {
        grid-column: span 1;
      }
    }

    img {
      border-radius: 4px;
    }

    .mobile-button {
      display: none;

      @media (max-width: 560px) {
        display: block;
        margin-top: 10px;

        .desktop-button {
          display: none;
          position: absolute
        }
      }
    }
  }
  
  .continue {
    background: #fff !important;
    padding: 50px 0;

    &__header {
      border-bottom: 1px solid #D8D8D8;
      margin-bottom: 30px;

      @media (max-width: 560px) {
        margin: 0 1px 30px;
      }

      &--link {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        text-decoration: none;
        bottom: 91px;
        align-items: center;

        @media (max-width: 560px) {
          display: none;
        }
  
        span {
          color: #000;
          text-transform: uppercase;
          font-size: 19px;
          font-weight: 700;
          line-height: 22.27px;
        }
        
      }
    }

    img {
      border-radius: 0;
    }

    .continue__img {
      width: 100%;
      height: 332px;
      object-fit: cover;
    }

    .continue__header--link-mobile {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      text-decoration: none;
      bottom: 91px;
      align-items: center;
      display: none;

      @media (max-width: 560px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }

      span {
        color: #000;
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 700;
        line-height: 22.27px;
      }
    }

    .container {
      overflow: hidden;

      @media (max-width: 560px) {
        padding: 0 20px;
      }
    }

    .col-12 {
      overflow: hidden;

      span {
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: block;
        
        img {
          transition: 0.4s;
    
          &:hover {
            transform: scale(1.06);
          }
        }  
      }

      @media (max-width: 560px) {
        display: none;
      }
    }

    .order-lg-1 {
      @media (max-width: 560px) {
        display: none;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 30px;
      line-height: 35.16px;
      color: #000;
      margin-bottom: 20px;

      @media (max-width: 992px) {
        font-size: 25px;
        line-height: 29.3px;
      }

      @media (max-width: 560px) {
        text-align: center;
      }
    }

    &__item {
      border: 1px solid #D8D8D8;
      border-radius: 6px;
      text-decoration: none;

      &--label {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: center;
        padding: 14px;
        text-transform: uppercase;
        background: #BE1A2E;
        color: white;
        font-weight: 600;
        transition: all 0.2s ease-in-out;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        @media (max-width: 560px) {
          padding: 15px;
        }
      }

      &--label-reverse {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: center;
        padding: 14px;
        text-transform: uppercase;
        background: black;
        color: white;
        font-weight: 600;
        transition: all 0.2s ease-in-out;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        @media (max-width: 560px) {
          padding: 15px;
        }
      }

      svg {
        margin-top: -2px;
      }

      &:hover {
        .continue__item--label {
          background: black;
        }
        .continue__item--label-reverse {
          background: #BE1A2E;
        }
      }

      &--content {
        padding: 30px;

        legend {
          font-weight: 700;
          font-size: 16px;
          line-height: 18.75px;
          color: #B02E34;
          text-transform: uppercase;

          @media (max-width: 992px) {
            font-size: 16px;
            line-height: 18.75px;
          }
        }

        h3 {
          font-weight: 700;
          font-size: 22px;
          line-height: 25.78px;
          color: #000;
          text-transform: uppercase;
          margin-bottom: 0;

          @media (max-width: 992px) {
            font-size: 22px;
            line-height: 25.78px;
          }
        }
      }
    }

  }

  .firstgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .firstgrid__item {
    grid-column: span 1;
  }
  
  .firstgrid__item:nth-child(n+3) {
    grid-column: span 1;
  }

  #videoPlayerHome,
  #videoPlayer {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    max-width: 1070px;
    min-height: 665px;
    object-fit: contain;

    @media screen and (max-width: 991px) {
      min-height: 100% !important;
      height: 100% !important;
      object-fit: contain !important;
    }
  }

  #videoPlayer:-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
  
  #videoPlayer:-moz-full-screen {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
  
  #videoPlayer:-ms-fullscreen {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
  
  #videoPlayer:fullscreen {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #B02E34;
    border: 2px solid white;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
  }
  
  .play-button svg {
    width: 40px;
    height: 40px;
  }
  
  .play-button:hover {
    opacity: 0.8;
  }
  
  .play-button.hidden {
    display: none;
  }
  
}