header {
  .c-menu__list {

    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }

    .phone-link,
    .member-link {
      width: 100%;
    }

    @media screen and (max-width: 991px) {
      .phone-link,
      .member-link {
        margin-left: 0 !important;
      }
    }

  }
}
.phone-link {
  background-color: #B02E34;
  background: #B02E34;

  &:hover {
    background-color: #000 !important;
    background: #000 !important;
    color: #fff !important;
  }
}

.c-header__logo-image {
  max-width: 200px !important;
  object-fit: contain;
}

.c-menu__link.phone-link--theme-primary {
  background-color: #BE1A2E;
  color: #FFFFFF;
  margin: 0;
  border: 0;
  box-shadow: none;
  padding: 16px 30px;

  .phone-link__text {
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 700;
  }
}

.member-link {
  background-color: #000;
  background: #000;

  &:hover {
    background-color: #B02E34 !important;
    background: #B02E34 !important;
  }
}

.c-menu__list {
  display: flex;
}

.progress-container {
  position: relative;
}

.progress-block {
  position: absolute;
  background: #fff;
  border-radius: 40px;
  padding: 1px 11px;
  bottom: 0;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 154px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  img {
    border-radius: 6px;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    box-shadow: none !important;
  }
}

.progress-wrapper {
  position: relative;
  background: #E2E2E2;
  width: 100%;
  border-radius: 35px;
}

.progress {
  background-color: #BE1A2E;
  width: 0;
  transition: width 0.4s linear;
  height: 7px;
  border-radius: 5px;
  position: relative;
}

.complete {
  font-size: 13px;
  line-height: 15.23px;
  font-weight: 700;
}