.LoginPage {
  background: #000;
  overflow: hidden;

  input[type=email], 
  input[type=password], 
  input[type=text] {
    height: 47px;
    font-size: 14px;
  }

  input::placeholder {
    font-size: 14px;
    color: #BCBCBC;
    line-height: 26.4px;
  }
  
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .c-header {
    position: relative;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    transition: all .2s ease-in-out;
    border-bottom: 1px solid #252525;
  }


  .member-link {
    margin-left: 15px;
  }

  .main {
    background: #000;
  }

  .c-footer {
    display: none;
  }

  .login-form-content {
    max-width: 500px;
    margin: 0 auto;

    @media (max-width: 560px) {
      max-width: 400px;
    }
  }
  

  .login-form-img-wrapper {
    height: 90vh;
  }

  .login-form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: calc(50% - 770px);

    .main-content {
      width: 430px;
    }
  
  }


  .desktop-login-form-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobile-login-form-img {
    width: 100%;
    display: none;
  }

  @media (max-width: 992px) {
    .login-form {
      margin-top: 70px;

      &__link {
        text-decoration: none !important;
      }
    }

    .desktop-login-form-img {
      display: none;
    }
    .mobile-login-form-img {
      display: block;
    }

    .login-form-row {
      display: flex;
      flex-direction: column-reverse;
      gap: 50px;
      justify-content: space-between;
      height: 100%;
      row-gap: 20px;
      column-gap: 30px;

      .login-form-img-wrapper {
        height: 245px;
        margin-bottom: 0 !important;
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 0;
        margin: 0;

        .login-form-img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }

  .error-field {
    border: 2px inset red;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .success {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .success-danger {
    color: #154a16;
    background-color: #b2edb3;
    border-color: #95db96;
  }

  .login-form {
    &__title {
      color: #fff;
      font-weight: 700;
      font-size: 30px;
      line-height: 35.16px;

      @media (max-width: 992px) {
        font-size: 25px;
        line-height: 29.3px;
      }
    }

    &__button-wrapper {
      margin-top: 23px;
      @media (max-width: 560px) {
        align-items: flex-start;
        justify-content: flex-start;
        .c-button {
          margin: 0;
        }
      }
    }

    &__link {
      margin-top: 20px;
      font-size: 14px;
      line-height: 26.4px;
      font-weight: 400;
      color: #fff;
      text-decoration: underline !important;

      @media (max-width: 991px) {
        text-decoration: none !important;
      }
    }

    .c-button {
      width: 100%;
    }

    &__password-block {
      position: relative;
      display: block;
    }
  }

  input[type=password] {
    z-index: 2;
    display: block;
    position: relative;
  }

  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9999;
    display: block;
  }
  
  .eye-icon svg {
    width: 25px;
    height: auto;
    margin-top: 0px;
    margin-right: 6px;
  }
  
  /* Hide the open eye icon by default */
  .eye-open {
    display: none;
  }
  
  #forgot-password-form {
    display: none;
  }

  /* Show the open eye icon when the password field is focused */
  input[type="password"]:focus + .eye-icon .eye-open {
    display: block;
  }
  
  /* Hide the closed eye icon when the password field is focused */
  input[type="password"]:focus + .eye-icon .eye-closed {
    display: none;
  }
}